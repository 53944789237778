<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">基本信息</a-divider>
			<a-descriptions :column="2">
				<a-descriptions-item label="仓库名称">
					{{ detail.storage_name||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="仓库编码">
					{{ detail.storage_id }}
				</a-descriptions-item>
				<a-descriptions-item label="仓库类型">
					{{ detail.storage_type_view }}
				</a-descriptions-item>
				<a-descriptions-item label="仓库状态">
					{{ detail.storage_state_view||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="仓库负责人">
					{{ detail.principal_name||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="仓库电话">
					{{ detail.storage_phone||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="所属机构编码">
					{{ detail.storage_belong||'--'}}
				</a-descriptions-item>
                <a-descriptions-item label="仓库地址">
                	{{detail.storage_address}}
                </a-descriptions-item>
                <a-descriptions-item label="工厂">
                    {{detail.factory_name}}
                    <a @click="factoryModal">
                        <a-icon type="plus-circle" />
                        <span style="padding-left: 5px">{{!detail.factory_id ? '添加' : '更改'}}</span>
                    </a>

                    <a-popconfirm placement="top" ok-text="确认" cancel-text="取消" @confirm="confirm" title="是否删除该仓库所属工厂？">
                        <a v-if="detail.factory_id">
                            <a-icon type="close-circle"/>
                            <span style="padding-left: 5px">删除</span>
                        </a>
                    </a-popconfirm>
                </a-descriptions-item>
				
				<a-descriptions-item label="是否带板" >
					<a-radio-group @change='handle_with_plate' v-model:value="detail.can_with_plate">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="0">否</a-radio>
					</a-radio-group>
				</a-descriptions-item>
				
			</a-descriptions>

			<a-divider orientation="left">承运商</a-divider>
            <div>
                <a @click="add_carrier()">
                    <a-icon type="plus-circle" />
                    <span style="padding-left: 5px">添加</span>
                </a>
                <!-- <a-input type="hidden" name="goods_data" v-decorator="['goods_data', carrier_data]" /> -->
                <div class="carrier_box">
                    <ul>
                        <li v-for="(item, index) in carrier_data" :key="index">
                            <div class="carrier_info">
                                <span class="title">承运商：</span><span>{{ item.merchant_name }}</span>
                                <span class="title">承运商编码：</span><span>{{ item.merchant_id }}</span>
                                <span class="title">承运商类型：</span><span>{{ item.merchant_type_desc }}</span>
                                <span class="title">联系人：</span><span>{{ item.linkman }}</span>
                                <span class="title">电话：</span><span>{{item.mobilephone}}</span>
                                <a @click="del_carrier(item)">删除</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

			<a-divider orientation="left">仓管信息</a-divider>
			<div>
                <a @click="add_manage()">
                    <a-icon type="plus-circle" />
                    <span style="padding-left: 5px">添加</span>
                </a>
                <!-- <a-input type="hidden" name="goods_data" v-decorator="['goods_data', carrier_data]" /> -->
                <div class="carrier_box">
                    <ul>
                        <li v-for="(item, index) in manage_data" :key="index">
                            <div class="carrier_info">
                                <span class="title">ID：</span><span>{{ item.uid }}</span>
                                <span class="title">名称：</span><span>{{ item.username }}</span>
                                <span class="title">电话：</span><span>{{ item.phone }}</span>
                                <span class="title">类型：</span><span>{{ item.type == 1 ? '空桶仓管' : '成品仓管' }}</span>
                                <a @click="del_manage(item)">删除</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
			
		
		</div>
        <EditPop @handleSubmit="carrier_submit" :form_data="form_data" :visible.sync="carrier_visible">		
		</EditPop>
        <EditPop @handleSubmit="ware_submit" :form_data="form_data_ware" :visible.sync="ware_visible">
			<template
                slot="user_data"
            	slot-scope="text,record"
            ><span style="margin-right: 10px;">{{user_data.username}}</span><a @click="add_user()">
                    <span style="padding-left: 5px">选择人员</span>
                </a></template>
		</EditPop>
		<SelectUser @submit="submit_manage" :visible.sync="visible_manage"></SelectUser>
        <!-- 选择工厂 -->
		<SelectFactory @submit="submitFactory" :visible.sync="visibleFactory" :select_type="'radio'"></SelectFactory>
	</div>
</template>

<script>
	import SelectFactory from '@/components/select_factory.vue'
	import {
		get_ware_detail,
		get_carrier_select,
		get_bind_carrier,
		del_warehouse_bind,
		save_ware_manage,
		del_ware_manage,
        changeFactory,
        delFactory,changeWarehouseWithPlate
	} from '@/api/push.js'
    import EditPop from "@/components/EditPop";
    import SelectUser from "@/components/SelectUser";
	export default {
		components: {
			EditPop,
			SelectUser,
            SelectFactory
		},
		data() {
			return {
				
                visibleFactory:false,
				detail: {},
                carrier_visible: false,
				visible_manage:false,
				ware_visible:false,
                carrier_data:[],
				manage_data:[],
				user_data:{},
                form_data: {
                    title: "添加承运商",
                    // show_submit_btn: true,
                    show_close_btn: true,
                    close_reset_btn: true,
                    list: [{
                            type: "select",
                            name: "carrier_id",
                            title: "承运商",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择承运商"
                                }]
                            },
                            list: []
                        },]
                },
				form_data_ware:{
					title:"添加仓管",
                    show_close_btn: true,
                    close_reset_btn: true,
                    list: [{
                            type: "select",
                            name: "type",
                            title: "类型",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择仓管类型"
                                }]
                            },
                            list: [
								{
									value:"空桶仓管",
									key:1,
								},
								{
									value:"成品仓管",
									key:2
								}
							]
                        },
					{
						type:"slot",
						name:"user_data",
						title:"选择人员"
					}]
				}
			}
		},
		created() {
			this.get_info()
		},
	
		methods: {
			// 设置批量带板
			handle_with_plate(){
				changeWarehouseWithPlate({data:{
					can_with_plate:this.detail.can_with_plate,
					ids:this.$route.query.id
				},info:true}).then(res=>{
					this.get_info()
				})
			},
			get_info() {
				get_ware_detail({
					data: {
						id: this.$route.query.id,
					}
				}).then(res => {
					this.detail = res.data.list
					this.carrier_data = res.data.list.carrier_data
					this.manage_data = res.data.list.manage_data
				})
			},
            add_carrier() {
                this.carrier_visible = true;
				get_carrier_select().then(res=>{
					let select_data = [];
					for(let i=0;i<res.data.list.length;i++) {
						select_data.push({key:res.data.list[i].value,value:res.data.list[i].label})
					}
					this.form_data.list[0]['list'] = select_data
				})
            },
			add_manage() {
				// this.visible_manage = true;
				this.ware_visible = true;
			},
			submit_manage({
                data
            }) {
                this.visible_manage = false;	
				this.user_data = {uid:data.id,username:data.username,phone:data.phone}
            },
			del_manage(e) {
				del_ware_manage({
					data:{
						id:this.$route.query.id,
						uid:e.uid
					}
				}).then(res=>{
					this.get_info()
				})
			},
			ware_submit(e) {
				this.ware_visible = false;
				let obj = {uid:this.user_data.uid,username:this.user_data.username,phone:this.user_data.phone,type:e.values.type}	
				save_ware_manage({
					data:{
						...obj,
						id:this.$route.query.id
					}
				}).then(res => {
					this.manage_data.push(obj)	
					this.get_info()
				})
			},
			add_user(){
				this.visible_manage = true;
			},
            //绑定经销商
            carrier_submit(e) {
                this.carrier_visible = false
				get_bind_carrier({
					data:{
						id:this.$route.query.id,
						merchant_id:e.values.carrier_id
					}
				}).then(res=>{
					this.get_info()
				})
            },
            //删除经承运商
            del_carrier(e) {
                del_warehouse_bind({
					data:{
						id:e.id
					}
				}).then(res=>{
					this.get_info();
				})
            },
            // 选择工厂
            factoryModal(){
                this.visibleFactory = true
            },
            // 选择工厂回调
            submitFactory(e){
                changeFactory({
                    data:{
                        id: this.$route.query.id,
                        factory_id:e.data.id
                    },
                    loading:true
                }).then(()=>{

                    this.get_info()  
                })
            },
            confirm(){
                delFactory({
                    data:{
                        id:this.$route.query.id,
                    },
                    loading:true
                }).then(()=>{
                    this.get_info()  
                })
            }
		}
	}
</script>

<style lang="less" scoped>
	.time_line {
		max-width: 800px;
	}

	.avatar_ul {
		display: flex;
		flex-wrap: wrap;

		.avatar {
			margin: 16px 10px 0;
			width: 80px;
		}
	}

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.li_item {
		width: 100%;
		.title_item {
			width: 80px;
			text-align: left;
			display: inline-block;
			color: #000;
		}

		margin-bottom: 30px;
	}

	.arpproval_title {
		padding: 15px 0;
		text-align: center;
		background: rgba(245, 245, 245, 1);
		font-size: 14px;
		span {
			width: 9%;
			display: inline-block;
		}

		.department_appr {
			width: 18%;
		}
	}

	.arpproval_title1 {
		height: auto;
		width: 300px;
		background: none;
		border: 1px solid rgba(245, 245, 245, 1);
		.department_appr {
			width: 50%;
		}
	}
    
	.arpproval_title2 {
		background: #fff;
		border: 1px solid rgba(245, 245, 245, 1);
	}

	.go_see {
		color: #1890ff;
	}

	.ant-descriptions-item-label {
		width: 100px;
	}

	.carrier_box{
		margin-top: 10px;
	}

    .carrier_info{
		margin-bottom: 5px;
		.title{
			color: rgba(0, 0, 0, 0.85);
		}
		span{
		   padding-right: 15px;
	   	}
	}
       .anticon-close-circle{
        margin-left: 10px;
        cursor: pointer;
        color: #397dee ;
    }
</style>
